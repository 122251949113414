import { Field } from 'react-final-form'
// import LocationAutocomplete from 'location-autocomplete'
import TextareaAutosize from 'react-autosize-textarea'
import classnames from 'classnames'
import { required as validateRequired } from './validators'

const Input = ({
  name,
  label,
  type,
  validate = false,
  selectOptions,
  selectLabel = 'label',
  selectValue = 'value',
  helper,
  maxLength,
  parse,
  className,
  id,
  required,
  emptySelectOption = true,
  emptySelectOptionTitle = '',
}) => (
  <Field
    name={name}
    validate={required ? validateRequired : validate}
    parse={value => (value === '' ? null : value)}>
    {({ input, meta }) => {
      const showError = (meta.error && meta.touched) || (meta.error && !meta.pristine)

      const renderElement = () => {
        switch (type) {
          case 'textarea':
            return <TextareaAutosize {...input} />
          case 'textAreaOld':
            return <textarea {...input} />
          case 'date':
            return <input type="date" {...input} />
          case 'select':
            return (
              <select name={name} {...input}>
                {emptySelectOption && <option>{emptySelectOptionTitle}</option>}
                {selectOptions.map((_, i) => (
                  <option key={i} value={selectOptions[i][selectValue]}>
                    {selectOptions[i][selectLabel]}
                  </option>
                ))}
              </select>
            )
          default:
            return <input {...input} type={type} />
        }
      }

      return (
        <>
          {helper && <span className="helper-text">*{helper}</span>}
          <div
            className={classnames('form-control-field', {
              'active-error': showError,
              [className]: !!className,
            })}>
            {type === 'select' && <i className="fa fa-caret-down" />}
            {renderElement()}
            <label
              htmlFor={id}
              className={classnames('input-label', {
                lifted: input.value || input.value === 0 || type === 'date' || emptySelectOptionTitle?.length,
              })}>
              {label}
            </label>

            {showError && <span className="helper-text error">{meta.error}</span>}

            {maxLength && (
              <span
                className={classnames('helper-text char-length', {
                  error: input.value.length > maxLength,
                })}>
                {input.value.length}/{maxLength}
              </span>
            )}
          </div>
        </>
      )
    }}
  </Field>
)

export default Input
