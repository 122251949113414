import isObject from 'lodash/isObject'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export function mapToCssModules(className = '', cssModule) {
  if (!cssModule) return className
  return className
    .split(' ')
    .map(c => cssModule[c] || c)
    .join(' ')
}

export const Container = ({ children, fullWidth, style, className, id }) => (
  <div
    className={`${fullWidth ? 'container-fluid' : 'container'} ${className || ''}`}
    style={style}
    id={id}>
    {children}
  </div>
)

Container.defaultProps = {
  fullWidth: false,
}

export const Row = ({ children, align, noGutters, ...rest }) => {
  const options = {
    center: 'justify-content-center',
    end: 'justify-content-end',
    between: 'justify-content-between',
    around: 'justify-content-around',
  }

  const className = `row ${options[align] || ''} ${noGutters ? 'no-gutters' : ''}`.trim()

  return (
    <div {...rest} className={className}>
      {children}
    </div>
  )
}

Row.defaultProps = {
  noGutters: false,
}

//
// <Col>
// See: https://reactstrap.github.io/components/layout/ for use
//
const colWidths = ['xs', 'sm', 'md', 'lg', 'xl']
const stringOrNumberProp = PropTypes.oneOfType([PropTypes.number, PropTypes.string])

const columnProps = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.number,
  PropTypes.string,
  PropTypes.shape({
    size: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    order: stringOrNumberProp,
    offset: stringOrNumberProp,
  }),
])

const getColumnSizeClass = (isXs, colWidth, colSize) => {
  if (colSize === true || colSize === '') {
    return isXs ? 'col' : `col-${colWidth}`
  } else if (colSize === 'auto') {
    return isXs ? 'col-auto' : `col-${colWidth}-auto`
  }

  return isXs ? `col-${colSize}` : `col-${colWidth}-${colSize}`
}

export const Col = props => {
  const { className, cssModule, widths, tag: Tag, ...attributes } = props
  const colClasses = []

  widths.forEach((colWidth, i) => {
    let columnProp = props[colWidth]

    delete attributes[colWidth]

    if (!columnProp && columnProp !== '') {
      return
    }

    const isXs = !i

    if (isObject(columnProp)) {
      const colSizeInterfix = isXs ? '-' : `-${colWidth}-`
      const colClass = getColumnSizeClass(isXs, colWidth, columnProp.size)

      colClasses.push(
        mapToCssModules(
          classnames({
            [colClass]: columnProp.size || columnProp.size === '',
            [`order${colSizeInterfix}${columnProp.order}`]:
              columnProp.order || columnProp.order === 0,
            [`offset${colSizeInterfix}${columnProp.offset}`]:
              columnProp.offset || columnProp.offset === 0,
          }),
          cssModule
        )
      )
    } else {
      const colClass = getColumnSizeClass(isXs, colWidth, columnProp)
      colClasses.push(colClass)
    }
  })

  if (!colClasses.length) {
    colClasses.push('col')
  }

  const classes = mapToCssModules(classnames(className, colClasses), cssModule)

  return <Tag {...attributes} className={classes} />
}

Col.defaultProps = {
  tag: 'div',
  widths: colWidths,
}

Col.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  xs: columnProps,
  sm: columnProps,
  md: columnProps,
  lg: columnProps,
  xl: columnProps,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  widths: PropTypes.array,
}
